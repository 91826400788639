export const theme = {
  standardBg: '#181632',
  cardBg: '#1D1D47',
  paragraphPrimary: '#EBEBEB',
  paragraphSecondary: '#181632',
  borderPrimary: '#03B4ED',
  LinkPrimary: '#F08535',
  buttonPrimary: 'linear-gradient(90deg, #FC6137 -8.24%, #F8A637 103.85%)',
  buttonPrimaryHover: 'linear-gradient(90deg, #EC5024 -8.24%, #FE9D19 103.85%)',
  buttonPrimaryDisabled: `
    linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), 
    linear-gradient(90deg, #FC6137 -8.24%, #F8A637 103.85%)
  `,
  imgGradientBg: `
    radial-gradient(50% 50% at 50% 50%, 
      #56CCF2 0%, 
      rgba(86, 204, 242, 0.395833) 31.77%, 
      rgba(86, 204, 242, 0) 100%
    )
  `,
  cardGradientBg: 'linear-gradient(215.5deg, #BBFCFF 19.62%, #4CCFFA 86.48%)',
}