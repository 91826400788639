import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { rem } from "../../styles";
import Socials from "../social/Socials";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: wrap;
  justify-content: space-between;
  padding: ${rem(40)} ${rem(64)};
  box-sizing: border-box;
`;

const LogoImg = styled.img`
  max-width: ${rem(200)};
`;

const RightBlock = styled.div`
  display: flex;
  align-items: stretch;
`;

const Links = styled.div`
  display: flex;
  align-items: center;

  a {
    font-size: ${rem(18)};
    color: #FFF;
    text-decoration: none;
    padding: 0 ${rem(30)};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const NAVS = [
  {
    to: '/about',
    title: 'About',
  },
  {
    to: '/portfolio',
    title: 'Portfolio',
  },  {
    to: '/careers',
    title: 'Careers',
  },
];

const Navigation = () => {
  const { pathname } = useLocation();

  return (
    <Container>
      <Link to="/">
        <LogoImg src="images/font-logo.png" alt="" />
      </Link>
      <RightBlock>
        <Links>
          {
            NAVS.map(({ to, title }) => (
              <Link key={title} to={to} style={{ fontWeight: pathname === to ? 600 : 400 }}>{title}</Link>
            ))
          }
        </Links>
        <Socials styles={{ paddingLeft: rem(36) }} />
      </RightBlock>
    </Container>
  )
}

export default Navigation;
