import React from 'react';
import Navigation from './components/navigation/Navigation';
import Routes from './pages/Routes';
import './App.scss';

function App() {
  return (
    <div>
      <Navigation />
      <Routes />
    </div>
  );
}

export default App;
