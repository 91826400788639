import { FC } from "react";
import styled from "styled-components";
import { rem } from "../../styles";
import { Email, Twitter } from "../../svgs";

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  a {
    display: flex;
    text-decoration: none;
    margin: 0 ${rem(20)};

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  svg {

  }
`;

const Socials: FC<{ styles?: object }> = ({ styles }) => {
  return (
    <Wrapper style={styles}>
      <a href="/" target="_blank" rel="noopener noreferrer">
        <Twitter />
      </a>
      <a href="/" target="_blank" rel="noopener noreferrer">
        <Email />
      </a>
    </Wrapper>
  )
}

export default Socials;