import React from "react";
import { Routes as Switch, Route, Navigate } from "react-router-dom";

const Home = React.lazy(() => import('./home/Home'));
const Portfolio = React.lazy(() => import('./portfolio/Portfolio'));
const Careers = React.lazy(() => import('./career/Careers'));
const About = React.lazy(() => import('./about/About'));

const Routes = () => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Switch>
    </React.Suspense>
  );
};

export default Routes;
